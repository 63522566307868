import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  old_password: Yup.string().required('Eski şifre zorunludur'),

  password: Yup.string()
    .required('Yeni şifre zorunludur')
    .min(6, 'Yeni şifre en az 6 karakter olmalıdır')
    .max(12, 'Yeni şifre en fazla 12 karakter olabilir'),

  password_again: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Şifreler eşleşmelidir')
    .required('Yeni şifre tekrarı zorunludur'),
});

export default validationSchema;
