import { FC } from 'react';
import { useFormik } from 'formik';
import { Flex, Input } from 'antd';
import { ModalDialog } from '..';
import { IProps, IInitialValues } from './type';
import styles from './index.module.scss';
import { INITIAL_VALUES } from './const';
import validationSchema from './validations';
import useHttpService from '../../services/hook';
import { RequestMethods } from '../../services/enum';
import { logout } from '../../helpers/Auth';

const ResetPasswordModal: FC<IProps> = (props) => {
  const executeService = useHttpService();

  const onSubmit = async (values: IInitialValues) => {
    await executeService<unknown>({
      method: RequestMethods.POST,
      url: 'auth/user/change-password/',
      data: values,
    });
    logout();
  };

  const formik = useFormik<IInitialValues>({
    initialValues: INITIAL_VALUES,
    validationSchema,
    onSubmit,
  });

  return (
    <ModalDialog
      title={props.title}
      subTitle={props.subTitle}
      open={props.open}
      handleOk={props.handleSubmit}
      handleCancel={props.handleCancel}
    >
      <Flex gap={30} vertical className={styles.container}>
        <Input
          placeholder="Eski Şifre"
          type="password"
          {...formik.getFieldProps('old_password')}
        />
        {formik.touched.old_password && formik.errors.old_password && (
          <div className={styles.error}>{formik.errors.old_password}</div>
        )}
        <Input
          placeholder="Yeni Şifre"
          type="password"
          {...formik.getFieldProps('password')}
        />
        {formik.touched.password && formik.errors.password && (
          <div className={styles.error}>{formik.errors.password}</div>
        )}
        <Input
          placeholder="Yeni Şifre Tekrar"
          type="password"
          {...formik.getFieldProps('password_again')}
        />
        {formik.touched.password_again && formik.errors.password_again && (
          <div className={styles.error}>{formik.errors.password_again}</div>
        )}
      </Flex>
    </ModalDialog>
  );
};

export default ResetPasswordModal;
