import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { DatePicker, Flex } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  AuthorizedButton,
  DataTable,
  MotorInsuranceModal,
  PageTitle,
} from '../../components';
import { tableActions } from '../../store/reducers/tableReducer';
import { IDataSource, IInitialValues, IOrganization } from './type';
import styles from './index.module.scss';
import { INITIAL_VALUES } from './const';
import { useAppDispatch, useAppSelector } from '../../store';
import useHttpService from '../../services/hook';
import { getColumns } from './columns';
import {
  validationSchemaForCreate,
  validationSchemaForEdit,
} from './validations';
import { IPage, ISearch, ISort } from '../../store/reducers/tableReducer/type';
import { RequestMethods } from '../../services/enum';
import {
  cleanupAfterSubmit,
  prepareFetchParameters,
  prepareFormData,
  prepareModalData,
} from './helpers';
import { IExpandedRow } from '../../components/DataTable/type';
import { IEnum } from '../../services/type';
import { IResponse } from '../../types';

const { RangePicker } = DatePicker;

const MotorInsurance: FC = () => {
  const search = useAppSelector((state) => state.tableReducer.search);
  const sort = useAppSelector((state) => state.tableReducer.sort);
  const page = useAppSelector((state) => state.tableReducer.page);
  const dispatch = useAppDispatch();
  const executeService = useHttpService();

  // State
  const [selectedItem, setSelectedItem] = useState<IDataSource | undefined>(
    undefined,
  );
  const [canceledItem, setCanceledItem] = useState<IDataSource | undefined>(
    undefined,
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<IDataSource[]>([]);
  const [expandedRows, setExpandedRows] = useState<IExpandedRow[]>([]);
  const [dates, setDates] = useState<string[]>([]);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [branches, setBranches] = useState<IEnum[]>([]);
  const [statusChoices, setStatusChoices] = useState<IEnum[]>([]);

  // Handlers
  const handleFetchOrganizations = async () => {
    const response = await executeService<IResponse<IOrganization>>({
      method: RequestMethods.GET,
      url: `auth/organization/`,
    });
    if (response && 'results' in response) {
      setOrganizations(response.results);
    }
  };

  const handleFetchEnums = async () => {
    const response = await executeService<IEnum[]>({
      method: RequestMethods.GET,
      url: `enums/enums/get-motor-insurance-branches/`,
    });
    if (response && Array.isArray(response)) {
      setBranches(response);
    }

    const response3 = await executeService<IEnum[]>({
      method: RequestMethods.GET,
      url: `enums/enums/get-all-status-choices/`,
    });
    if (response3 && Array.isArray(response3)) {
      setStatusChoices(response3);
    }
  };

  const handleFetchData = async (
    dates?: string[],
    search?: ISearch,
    sort?: ISort,
    page?: IPage,
  ) => {
    const parameters = prepareFetchParameters(dates, search, sort, page);
    const response = await executeService<IResponse<IDataSource>>({
      method: RequestMethods.GET,
      url: `motor-insurance/motor-insurance/${parameters}`,
    });
    if (response && 'results' in response) {
      dispatch(tableActions.changeTableTotal(response.count));
      const results = response.results.reduce<IDataSource[]>(
        (prev: IDataSource[], cur: IDataSource) => {
          return [...prev, { ...cur, key: cur.id }];
        },
        [],
      );
      setDataSource(results);
    }
  };

  const handleClickEye = async (id: number) => {
    dispatch(tableActions.changeTableRow(id));
    const expandedRows = await executeService<IExpandedRow[]>({
      method: RequestMethods.GET,
      url: `motor-insurance/motor-insurance/${id}/get-latest-processes/`,
    });
    expandedRows &&
      Array.isArray(expandedRows) &&
      setExpandedRows(expandedRows);
  };

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    formik.setErrors({});
    formik.setTouched({});
  };

  const handleOpenEditModal = async (id: number) => {
    const selectedItem = await executeService<IDataSource>({
      method: RequestMethods.GET,
      url: `motor-insurance/motor-insurance/${id}/`,
    });
    selectedItem && 'id' in selectedItem && setSelectedItem(selectedItem);
  };

  const handleCloseEditModal = () => {
    setSelectedItem(undefined);
    formik.setErrors({});
    formik.setTouched({});
  };

  const handleUploadFile = (e: ChangeEvent<HTMLInputElement>) =>
    formik.setFieldValue('upload_files', e.target.files);

  const handleClickCancel = async (id: number) => {
    const canceledItem = await executeService<IDataSource>({
      method: RequestMethods.GET,
      url: `motor-insurance/motor-insurance/${id}/`,
    });
    canceledItem && 'id' in canceledItem && setCanceledItem(canceledItem);
  };
  const handleCloseCancel = () => setCanceledItem(undefined);

  const handleCancelPolicy = async () => {
    try {
      if (canceledItem) {
        const formData = new FormData();
        formData.append('id', formik.values.id?.toString() || '0');
        formData.append('file', formik.values.upload_files[0]);
        formData.append('description', formik.values.description);
        await executeService<unknown>({
          method: RequestMethods.POST,
          url: `motor-insurance/motor-insurance/${canceledItem.id}/cancel-motor-insurance/`,
          data: formData,
          contentType: 'multipart/form-data',
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      cleanupAfterSubmit(
        setIsModalOpen,
        setCanceledItem,
        formik.resetForm,
        handleFetchData,
      );
      dispatch(tableActions.changeTablePage({ number: 0, size: 10 }));
    }
  };

  const handleClickDelete = async (id: number) => {
    try {
      await executeService<unknown>({
        method: RequestMethods.DELETE,
        url: `motor-insurance/motor-insurance/${id}/`,
      });
    } catch (e) {
      console.log(e);
    } finally {
      cleanupAfterSubmit(
        setIsModalOpen,
        setSelectedItem,
        formik.resetForm,
        handleFetchData,
      );
      dispatch(tableActions.changeTablePage({ number: 0, size: 10 }));
    }
  };

  const handleSubmit = async (values: IInitialValues) => {
    try {
      const formData = prepareFormData(values);

      if (selectedItem) {
        const statusFormData = new FormData();
        statusFormData.append('status', values.recent_status);
        await executeService<IDataSource>({
          method: RequestMethods.PATCH,
          url: `motor-insurance/motor-insurance/${selectedItem.id}/`,
          data: formData,
          contentType: 'multipart/form-data',
        });
        await executeService<IDataSource>({
          method: RequestMethods.POST,
          url: `motor-insurance/motor-insurance/${selectedItem.id}/update-status/`,
          data: statusFormData,
          contentType: 'multipart/form-data',
        });
      } else {
        await executeService<IDataSource>({
          method: RequestMethods.POST,
          url: 'motor-insurance/motor-insurance/',
          data: formData,
          contentType: 'multipart/form-data',
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      cleanupAfterSubmit(
        setIsModalOpen,
        setSelectedItem,
        formik.resetForm,
        handleFetchData,
      );
      dispatch(tableActions.changeTablePage({ number: 0, size: 20 }));
    }
  };

  // Columns
  const columns = getColumns(
    handleClickEye,
    handleOpenEditModal,
    handleClickDelete,
    handleClickCancel,
    organizations,
    branches,
    statusChoices,
  );

  // Form Management
  const formik = useFormik<IInitialValues>({
    initialValues: INITIAL_VALUES,
    validationSchema: !selectedItem
      ? validationSchemaForCreate
      : validationSchemaForEdit,
    onSubmit: handleSubmit,
  });

  // Effects
  useEffect(() => {
    handleFetchOrganizations();
    handleFetchEnums();
  }, []);

  useEffect(() => {
    if (selectedItem) {
      const result = prepareModalData(selectedItem);
      result && formik.setValues(result);
    } else {
      formik.setValues(INITIAL_VALUES);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (canceledItem) {
      const result = prepareModalData(canceledItem);
      result &&
        formik.setValues({ ...result, description: '', upload_files: [] });
    } else {
      formik.setValues(INITIAL_VALUES);
    }
  }, [canceledItem]);

  useEffect(() => {
    handleFetchData(dates, search, sort, page);
  }, [
    dates,
    search.key,
    search.value,
    sort.key,
    sort.order,
    page.number,
    page.size,
  ]);
  console.log(formik.values.upload_files);
  // JSX Return
  return (
    <Flex vertical gap={40} className={styles.container}>
      <PageTitle title="Oto Poliçeler" />
      <Flex vertical gap={20} className={styles.tableContainer}>
        <Flex justify="space-between" className={styles.buttonContainer}>
          <RangePicker
            placeholder={['Başlangıç Tarihi', 'Bitiş Tarihi']}
            className={styles.datePicker}
            onChange={(_, dates: [string, string]) => setDates(dates)}
          />
          <AuthorizedButton
            model="motorinsurance"
            action="add"
            className={styles.button}
            onClick={handleOpenModal}
          >
            <PlusOutlined className={styles.buttonIcon} />
            <div className={styles.buttonText}>Yeni Poliçe Talebi</div>
          </AuthorizedButton>
        </Flex>
        <DataTable<IDataSource>
          scrollable
          columns={columns}
          dataSource={dataSource}
          expandedRows={expandedRows}
          statusChoices={statusChoices}
        />
      </Flex>
      <MotorInsuranceModal
        type="CREATE"
        open={isModalOpen}
        title="Yeni Poliçe Talebi"
        subTitle="Araç Sigortaları İçin Yeni Poliçe Talebi Oluşturma"
        formik={formik}
        organizations={organizations}
        handleUploadFile={handleUploadFile}
        handleSubmit={formik.handleSubmit}
        handleCancel={handleCloseModal}
        branches={branches}
        statusChoices={statusChoices}
      />
      <MotorInsuranceModal
        type="EDIT"
        open={!!selectedItem}
        title="Poliçe Talebi Güncelleme"
        subTitle="Araç Sigortaları İçin Poliçe Talebi Güncelleme"
        formik={formik}
        organizations={organizations}
        handleUploadFile={handleUploadFile}
        handleSubmit={formik.handleSubmit}
        handleCancel={handleCloseEditModal}
        branches={branches}
        statusChoices={statusChoices}
      />
      <MotorInsuranceModal
        type="CANCEL"
        open={!!canceledItem}
        title="Poliçe İptal Talebi"
        subTitle="Araç Sigortaları İçin Poliçe İptal Talebi Oluşturma"
        formik={formik}
        organizations={organizations}
        handleUploadFile={handleUploadFile}
        handleSubmit={handleCancelPolicy}
        handleCancel={handleCloseCancel}
        branches={branches}
        statusChoices={statusChoices}
      />
    </Flex>
  );
};

export default MotorInsurance;
