import { FC, useRef } from 'react';
import {
  Button,
  Checkbox,
  DatePicker,
  Flex,
  Input,
  Select,
  Typography,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ModalDialog } from '..';
import { IProps } from './type';
import styles from './index.module.scss';
import dayjs from 'dayjs';
import { CheckSibaOrganization } from '../../helpers/Organization';
import { useAppSelector } from '../../store';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

const DomesticInsuranceModal: FC<IProps> = (props) => {
  const { getFieldProps, setFieldValue, values, errors, touched } =
    props.formik;
  const uploadInput = useRef<HTMLInputElement | null>(null);
  const { organization } = useAppSelector(
    (state) => state.userReducer.userInfo,
  );

  return (
    <ModalDialog
      title={props.title}
      subTitle={props.subTitle}
      open={props.open}
      handleOk={props.handleSubmit}
      handleCancel={props.handleCancel}
    >
      <Flex gap="middle" vertical>
        <Flex align="flex-start" justify="space-between">
          <Typography className={styles.labelTitle}>Firma Bilgileri</Typography>
          <Select
            id="organization"
            value={
              props.organizations.find(
                (item) => item.id === values.organization || organization,
              )?.name || undefined
            }
            placeholder="Firma Seçiniz"
            style={{ width: 200 }}
            disabled={!CheckSibaOrganization()}
            options={props.organizations.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            onChange={(value) => setFieldValue('organization', value)}
          />
          {touched.organization && errors.organization && (
            <div className={styles.error}>{errors.organization}</div>
          )}
        </Flex>
        <Flex gap="middle" justify="space-between">
          <Flex gap="small" vertical style={{ width: '50%' }}>
            <Typography className={styles.labelTitle}>
              Poliçe Bilgileri
            </Typography>
            <Input
              placeholder="Poliçe No"
              {...getFieldProps('policy_number')}
            />
            {touched.policy_number && errors.policy_number && (
              <div className={styles.error}>{errors.policy_number}</div>
            )}
          </Flex>
          <Flex
            gap="small"
            vertical
            justify="flex-end"
            style={{ width: '50%' }}
          >
            <Select
              id="branch"
              value={values.branch || undefined}
              placeholder="Branş"
              options={props.branches.map((b) => ({
                value: b.key,
                label: b.value,
              }))}
              onChange={(value) => setFieldValue('branch', value)}
            />
            {touched.branch && errors.branch && (
              <div className={styles.error}>{errors.branch}</div>
            )}
          </Flex>
        </Flex>
        <Flex justify="space-between">
          <Typography className={styles.uploadName}>
            {values.upload_files?.[0]?.name || 'Ek Dosya'}
          </Typography>
          <Flex vertical align="flex-end">
            <input
              hidden
              ref={uploadInput}
              type="file"
              onChange={props.handleUploadFile}
            />
            <Button
              className={styles.button}
              onClick={() => uploadInput.current?.click()}
            >
              <PlusOutlined className={styles.buttonIcon} />
              <div className={styles.buttonText}>Dosya Ekle</div>
            </Button>
          </Flex>
        </Flex>
        <Flex gap="small" vertical>
          <Typography className={styles.labelTitle}>Tarih Aralıgı</Typography>
          <Flex gap="middle">
            <DatePicker
              id="start_date"
              name="start_date"
              value={
                values.start_date
                  ? dayjs(values.start_date, 'YYYY-MM-DD')
                  : null
              }
              className={styles.input}
              placeholder="Başlangıç Tarihi"
              onChange={(date) =>
                setFieldValue('start_date', date.format('YYYY-MM-DD'))
              }
            />
            {touched.start_date && errors.start_date && (
              <div className={styles.error}>{errors.start_date}</div>
            )}
            <DatePicker
              id="end_date"
              name="end_date"
              value={
                values.end_date ? dayjs(values.end_date, 'YYYY-MM-DD') : null
              }
              className={styles.input}
              placeholder="Bitiş Tarihi"
              onChange={(date) =>
                setFieldValue('end_date', date.format('YYYY-MM-DD'))
              }
            />
            {touched.end_date && errors.end_date && (
              <div className={styles.error}>{errors.end_date}</div>
            )}
          </Flex>
        </Flex>
        {props.type === 'EDIT' && (
          <Flex justify="space-between">
            <Typography className={styles.labelTitle}>Durum Bilgisi</Typography>
            <Select
              id="recent_status"
              value={values.recent_status}
              style={{ width: 175 }}
              className={styles[`statusDropdown-${values.recent_status}`]}
              options={props.statusChoices.map((sc) => ({
                value: sc.key,
                label: sc.value,
              }))}
              onChange={(value) => setFieldValue('recent_status', value)}
            />
          </Flex>
        )}
        {props.type === 'EDIT' && (
          <Flex gap="small" align="flex-start">
            <Checkbox
              id="email_permission"
              name="email_permission"
              checked={values.email_permission}
              onChange={(e: CheckboxChangeEvent) =>
                setFieldValue('email_permission', e.target.checked)
              }
            />
            <div className={styles.mailCheckbox}>
              Durum Bilgisi ‘Onaylandı’ Olarak Güncellendiğinde Poliçe
              Bilgilerini Firmanın İlgili Modül Kullanıcılarına Mail Olarak
              İlet.
            </div>
          </Flex>
        )}
      </Flex>
    </ModalDialog>
  );
};

export default DomesticInsuranceModal;
