import { FC } from 'react';
import { Flex, Input, Typography } from 'antd';
import { ModalDialog } from '..';
import { IProps } from './type';
import styles from './index.module.scss';
import TextArea from 'antd/es/input/TextArea';

const OrganizationModal: FC<IProps> = (props) => {
  const { getFieldProps, errors, touched } = props.formik;

  return (
    <ModalDialog
      title={props.title}
      subTitle={props.subTitle}
      open={props.open}
      handleOk={props.handleSubmit}
      handleCancel={props.handleCancel}
    >
      <Flex gap="middle" vertical>
        <Flex vertical gap={16}>
          <Flex vertical gap={4}>
            <Typography className={styles.labelTitle}>
              Firma Bilgileri
            </Typography>
            <Flex vertical gap={4}>
              <Input placeholder="Firma Adı" {...getFieldProps('name')} />
              {touched.name && errors.name && (
                <div className={styles.error}>{errors.name}</div>
              )}
            </Flex>
          </Flex>
          <Flex vertical>
            <TextArea placeholder="Adres" {...getFieldProps('address')} />
            {touched.address && errors.address && (
              <div className={styles.error}>{errors.address}</div>
            )}
          </Flex>
        </Flex>
        <div>
          <Typography className={styles.labelTitle}>
            Kullanıcı Bilgileri
          </Typography>
          <Flex gap="middle" justify="space-between">
            <Flex vertical style={{ width: '50%' }}>
              <Input
                placeholder="Vergi Numarası"
                {...getFieldProps('tax_number')}
              />
              {touched.tax_number && errors.tax_number && (
                <div className={styles.error}>{errors.tax_number}</div>
              )}
            </Flex>
            <Flex vertical style={{ width: '50%' }}>
              <Input
                placeholder="Vergi Dairesi"
                {...getFieldProps('tax_office')}
              />
              {touched.tax_office && errors.tax_office && (
                <div className={styles.error}>{errors.tax_office}</div>
              )}
            </Flex>
          </Flex>
        </div>
      </Flex>
    </ModalDialog>
  );
};

export default OrganizationModal;
