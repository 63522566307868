import { FC, useEffect, useRef } from 'react';
import {
  Button,
  Checkbox,
  DatePicker,
  Flex,
  Input,
  Select,
  Typography,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { ModalDialog } from '../';
import { IProps } from './type';
import styles from './index.module.scss';
import { CheckSibaOrganization } from '../../helpers/Organization';
import { useAppSelector } from '../../store';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

const DamageNotificationModal: FC<IProps> = (props) => {
  const { getFieldProps, setFieldValue, values, errors, touched } =
    props.formik;
  const uploadInput = useRef<HTMLInputElement | null>(null);
  const { organization } = useAppSelector(
    (state) => state.userReducer.userInfo,
  );

  useEffect(() => {
    if (values.damage_type === 'domestic') setFieldValue('country', 'TR');
    else if (values.damage_type === 'abroad') setFieldValue('city', '');
    else if (values.damage_type === 'transportation') {
      setFieldValue('city', '');
      setFieldValue('country', '');
      setFieldValue('location_code', '');
    }
  }, [values.damage_type, setFieldValue]);

  return (
    <ModalDialog
      title={props.title}
      subTitle={props.subTitle}
      open={props.open}
      handleOk={props.handleSubmit}
      handleCancel={props.handleCancel}
    >
      <Flex gap="middle" vertical>
        <Flex align="flex-start" justify="space-between">
          <Typography className={styles.labelTitle}>Firma Bilgileri</Typography>
          <Select
            id="organization"
            value={
              props.organizations.find(
                (item) => item.id === values.organization || organization,
              )?.name || undefined
            }
            placeholder="Firma Seçiniz"
            style={{ width: 200 }}
            className={styles.input}
            disabled={!CheckSibaOrganization()}
            options={props.organizations.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            onChange={(value) => setFieldValue('organization', value)}
          />
          {touched.organization && errors.organization && (
            <div className={styles.error}>{errors.organization}</div>
          )}
        </Flex>
        <Flex vertical gap={12}>
          <Typography className={styles.labelTitle}>Hasar Bilgileri</Typography>
          <Flex gap="middle" justify="space-between">
            <Flex vertical style={{ width: '25%' }}>
              <Select
                placeholder="Hasar Tipi"
                options={props.damageTypes.map((dt) => ({
                  value: dt.key,
                  label: dt.value,
                }))}
                value={values.damage_type || undefined}
                onChange={(value) => setFieldValue('damage_type', value)}
              />
              {touched.damage_type && errors.damage_type && (
                <div className={styles.error}>{errors.damage_type}</div>
              )}
            </Flex>
            <Flex vertical style={{ width: '25%' }}>
              <Select
                placeholder="Ülke"
                disabled={
                  values.damage_type === 'transportation' ||
                  values.damage_type === 'domestic'
                }
                options={props.countries.map((c) => ({
                  value: c.key,
                  label: c.value,
                }))}
                value={values.country || undefined}
                onChange={(value) => setFieldValue('country', value)}
              />
              {touched.country && errors.country && (
                <div className={styles.error}>{errors.country}</div>
              )}
            </Flex>
            <Flex vertical style={{ width: '25%' }}>
              <Select
                disabled={
                  values.damage_type === 'transportation' ||
                  values.damage_type === 'abroad'
                }
                placeholder="Şehir"
                options={props.cities.map((c) => ({
                  value: c.key,
                  label: c.value,
                }))}
                value={values.city || undefined}
                onChange={(value) => setFieldValue('city', value)}
              />
              {touched.city && errors.city && (
                <div className={styles.error}>{errors.city}</div>
              )}
            </Flex>
            <Flex vertical style={{ width: '25%' }}>
              <Input
                disabled={values.damage_type === 'transportation'}
                placeholder="Lokasyon Kodu"
                {...getFieldProps('location_code')}
              />
              {touched.location_code && errors.location_code && (
                <div className={styles.error}>{errors.location_code}</div>
              )}
            </Flex>
          </Flex>
          <Flex gap="middle" justify="space-between">
            <Flex vertical style={{ width: '25%' }}>
              <Select
                placeholder="Hasar Branşı"
                options={props.branches.map((b) => ({
                  value: b.key,
                  label: b.value,
                }))}
                value={values.branch || undefined}
                onChange={(value) => setFieldValue('branch', value)}
              />
              {touched.branch && errors.branch && (
                <div className={styles.error}>{errors.branch}</div>
              )}
            </Flex>
            <Flex vertical style={{ width: '25%' }}>
              <DatePicker
                placeholder="Hasar Tarihi"
                format="YYYY-MM-DD"
                value={
                  dayjs(values.damage_date).isValid()
                    ? dayjs(values.damage_date)
                    : null
                }
                onChange={(date) =>
                  setFieldValue(
                    'damage_date',
                    dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ'),
                  )
                }
              />
              {touched.damage_date && errors.damage_date && (
                <div className={styles.error}>{errors.damage_date}</div>
              )}
            </Flex>
            <Flex vertical style={{ width: '25%' }}>
              <Input
                id="pending_loss"
                name="pending_loss"
                value={values.pending_loss}
                placeholder="Muallak Hasar Bedeli"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue(
                    'pending_loss',
                    e.target.value.replace(/[^0-9.,]/g, ''),
                  );
                }}
              />
              {touched.pending_loss && errors.pending_loss && (
                <div className={styles.error}>{errors.pending_loss}</div>
              )}
            </Flex>
            <Flex vertical style={{ width: '25%' }}>
              <Select
                placeholder="Değer Birimi"
                options={props.currencies.map((c) => ({
                  value: c.key,
                  label: c.value,
                }))}
                value={values.currency || undefined}
                onChange={(value) => setFieldValue('currency', value)}
              />
              {touched.currency && errors.currency && (
                <div className={styles.error}>{errors.currency}</div>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex vertical gap={12}>
          <div>
            <Typography className={styles.labelTitle}>
              Dosya Bilgileri
            </Typography>
            <Typography className={styles.description}>
              * Bu alandaki bilgiler Siba Sigorta tarafından doldurulacaktır.
            </Typography>
          </div>
          <Flex gap="middle" justify="space-between">
            <Flex vertical style={{ width: '30%' }}>
              <Input
                placeholder="Dosya No"
                disabled={props.type === 'CREATE'}
                {...getFieldProps('policy_number')}
              />
              {touched.policy_number && errors.policy_number && (
                <div className={styles.error}>{errors.policy_number}</div>
              )}
            </Flex>
            <Flex vertical style={{ width: '30%' }}>
              <Input
                placeholder="Eksper"
                disabled={props.type === 'CREATE'}
                {...getFieldProps('expert')}
              />
              {touched.expert && errors.expert && (
                <div className={styles.error}>{errors.expert}</div>
              )}
            </Flex>
            <Flex vertical style={{ width: '30%' }}>
              <Input
                placeholder="Raportör"
                disabled={props.type === 'CREATE'}
                {...getFieldProps('reporter')}
              />
              {touched.reporter && errors.reporter && (
                <div className={styles.error}>{errors.reporter}</div>
              )}
            </Flex>
          </Flex>
          <Flex justify="space-between">
            <Typography>
              {values.upload_files?.[0]?.name || 'Ek Dosya'}
            </Typography>
            <Flex vertical align="flex-end">
              <input
                hidden
                ref={uploadInput}
                type="file"
                onChange={props.handleUploadFile}
              />
              <Button
                className={styles.button}
                onClick={() => uploadInput.current?.click()}
              >
                <PlusOutlined className={styles.buttonIcon} />
                <div className={styles.buttonText}>Dosya Ekle</div>
              </Button>
            </Flex>
          </Flex>
          {props.type === 'EDIT' && (
            <Flex justify="space-between">
              <Typography className={styles.labelTitle}>
                Durum Bilgisi
              </Typography>
              <Select
                id="recent_status"
                value={values.recent_status}
                style={{ width: 175 }}
                className={styles[`statusDropdown-${values.recent_status}`]}
                options={props.statusChoices.map((sc) => ({
                  value: sc.key,
                  label: sc.value,
                }))}
                onChange={(value) => setFieldValue('recent_status', value)}
              />
            </Flex>
          )}
        </Flex>
        <div>
          <Typography className={styles.labelTitle}>Açıklama</Typography>
          <Flex vertical>
            <Input.TextArea
              placeholder="Açıklama"
              {...getFieldProps('description')}
              rows={3}
            />
            {touched.description && errors.description && (
              <div className={styles.error}>{errors.description}</div>
            )}
          </Flex>
        </div>
        <Typography className={styles.time}>
          {dayjs().format('YYYY-MM-DD - HH:mm')}
        </Typography>
        {props.type === 'EDIT' && (
          <Flex gap="small" align="flex-start">
            <Checkbox
              id="email_permission"
              name="email_permission"
              checked={values.email_permission}
              onChange={(e: CheckboxChangeEvent) =>
                setFieldValue('email_permission', e.target.checked)
              }
            />
            <div className={styles.mailCheckbox}>
              Durum Bilgisi ‘Onaylandı’ Olarak Güncellendiğinde Hasar
              Bilgilerini Firmanın İlgili Modül Kullanıcılarına Mail Olarak
              İlet.
            </div>
          </Flex>
        )}
      </Flex>
    </ModalDialog>
  );
};

export default DamageNotificationModal;
