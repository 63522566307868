import { FC, useEffect } from 'react';
import { Button, Flex, Input, Select, Typography } from 'antd';
import dayjs from 'dayjs';
import { ModalDialog } from '../';
import { IProps } from './type';
import styles from './index.module.scss';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { deactivation_period_months_options } from '../../assets/conts';

const UserModal: FC<IProps> = (props) => {
  const { getFieldProps, setFieldValue, values, errors, touched } =
    props.formik;

  const navigate = useNavigate();

  const handleClickCreateProfile = () =>
    navigate('/user-profiles-and-authorisation/profile-settings');

  useEffect(() => {
    if (values.organization) {
      props.setSelectedOrganization(values.organization);
    }
  }, [values.organization, props.setSelectedOrganization]);

  return (
    <ModalDialog
      title={props.title}
      subTitle={props.subTitle}
      open={props.open}
      handleOk={props.handleSubmit}
      handleCancel={props.handleCancel}
    >
      <Flex gap="middle" vertical>
        <div>
          <Typography className={styles.labelTitle}>Firma Bilgileri</Typography>
          <Flex gap="middle" justify="space-between">
            <Flex vertical style={{ width: '33%' }}>
              <Select
                id="organization"
                value={
                  props.organizations.find(
                    (item) => item.id === values.organization,
                  )?.name || undefined
                }
                placeholder="Firma Seçiniz"
                className={styles.input}
                options={props.organizations.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                onChange={(value) => setFieldValue('organization', value)}
              />
              {touched.organization && errors.organization && (
                <div className={styles.error}>{errors.organization}</div>
              )}
            </Flex>
            <Flex vertical style={{ width: '33%' }}>
              <Input
                placeholder="Departman Adı"
                {...getFieldProps('department')}
              />
              {touched.department && errors.department && (
                <div className={styles.error}>{errors.department}</div>
              )}
            </Flex>
            <Flex vertical style={{ width: '33%' }}>
              <Input
                placeholder="Pozisyon Adı"
                {...getFieldProps('position')}
              />
              {touched.position && errors.position && (
                <div className={styles.error}>{errors.position}</div>
              )}
            </Flex>
          </Flex>
        </div>
        <div>
          <Typography className={styles.labelTitle}>
            Kullanıcı Bilgileri
          </Typography>
          <Flex gap="middle" justify="space-between">
            <Flex vertical style={{ width: '33%' }}>
              <Input placeholder="İsim" {...getFieldProps('first_name')} />
              {touched.first_name && errors.first_name && (
                <div className={styles.error}>{errors.first_name}</div>
              )}
            </Flex>
            <Flex vertical style={{ width: '33%' }}>
              <Input placeholder="Soyisim" {...getFieldProps('last_name')} />
              {touched.last_name && errors.last_name && (
                <div className={styles.error}>{errors.last_name}</div>
              )}
            </Flex>
            <Flex vertical style={{ width: '33%' }}>
              <Select
                id="deactivation_period_months"
                value={values.deactivation_period_months}
                placeholder="Aktiflik Süresi"
                options={deactivation_period_months_options}
                onChange={(value) =>
                  setFieldValue('deactivation_period_months', value)
                }
              />
              {touched.deactivation_period_months &&
                errors.deactivation_period_months && (
                  <div className={styles.error}>
                    {errors.deactivation_period_months}
                  </div>
                )}
            </Flex>
          </Flex>
        </div>
        <div>
          <Typography className={styles.labelTitle}>
            İletişim Bilgileri
          </Typography>
          <Flex gap="middle" justify="space-between">
            <Flex vertical style={{ width: '50%' }}>
              <Input placeholder="Email" {...getFieldProps('email')} />
              {touched.email && errors.email && (
                <div className={styles.error}>{errors.email}</div>
              )}
            </Flex>
            <Flex vertical style={{ width: '50%' }}>
              <Input placeholder="Telefon" {...getFieldProps('phone_number')} />
              {touched.phone_number && errors.phone_number && (
                <div className={styles.error}>{errors.phone_number}</div>
              )}
            </Flex>
          </Flex>
        </div>
        <div>
          <Typography className={styles.labelTitle}>
            Profil Bilgileri
          </Typography>
          <Flex gap="middle" justify="space-between">
            <Flex vertical style={{ width: '33%' }}>
              <Select
                id="role"
                value={
                  props.roles.find((item) => item.id === values.role)
                    ?.external_name || undefined
                }
                placeholder="Profil"
                options={props.roles.map((role) => ({
                  label: role.external_name,
                  value: role.id,
                }))}
                onChange={(value) => setFieldValue('role', value)}
              />
            </Flex>
            <Flex vertical style={{ width: '33%' }}>
              <Button
                className={styles.addButton}
                onClick={handleClickCreateProfile}
              >
                <PlusOutlined className={styles.buttonIcon} />
                <div className={styles.buttonText}>Profil Oluştur</div>
              </Button>
            </Flex>
          </Flex>
        </div>
        {props.type === 'EDIT' && (
          <>
            <div>
              <Flex justify="space-between">
                <Typography className={styles.labelTitle}>
                  Oluşturan Kullanıcı
                </Typography>
                <Typography className={styles.labelValue}>
                  {values.created_by}
                </Typography>
              </Flex>
            </div>
            <div>
              <Flex justify="space-between">
                <Typography className={styles.labelTitle}>
                  Kayıt Tarihi
                </Typography>
                <Typography className={styles.labelValue}>
                  {values.created_at
                    ? dayjs(values.created_at).format('YYYY-MM-DD')
                    : null}
                </Typography>
              </Flex>
            </div>
          </>
        )}
      </Flex>
    </ModalDialog>
  );
};

export default UserModal;
