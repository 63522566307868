import { FC, Key } from 'react';
import { Flex, Input, Select, Tree, Typography } from 'antd';
import { ModalDialog } from '..';
import { IProps } from './type';
import { leftTreeData, rightTreeData } from './const';
import styles from './index.module.scss';
import { CheckSibaOrganization } from '../../helpers/Organization';

const ProfileModal: FC<IProps> = (props) => {
  const { getFieldProps, setFieldValue, values, errors, touched } =
    props.formik;

  return (
    <ModalDialog
      title={props.title}
      subTitle={props.subTitle}
      open={props.open}
      handleOk={props.handleSubmit}
      handleCancel={props.handleCancel}
    >
      <Flex gap="middle" vertical>
        {props.type === 'CREATE' && (
          <Flex align="flex-start" justify="space-between">
            <Typography className={styles.label}>Firma Bilgileri</Typography>
            <Select
              id="organization"
              value={values.organization}
              defaultValue={values.organization}
              placeholder="Firma Seçiniz"
              style={{ width: 200 }}
              disabled={!CheckSibaOrganization()}
              options={props.organizations.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={(value) => setFieldValue('organization', value)}
            />
            {touched.organization && errors.organization && (
              <div className={styles.error}>{errors.organization}</div>
            )}
          </Flex>
        )}

        <Flex vertical>
          <Typography className={styles.label}>Profil Bilgileri</Typography>
          <Input placeholder="Profil Adı" {...getFieldProps('name')} />
          {touched.name && errors.name && (
            <div className={styles.error}>{errors.name}</div>
          )}
        </Flex>
        <Flex vertical>
          <Typography className={styles.label}>Yetki Alanları</Typography>
          <Flex justify="space-around">
            <Tree
              checkable
              defaultExpandAll
              treeData={leftTreeData}
              className={styles.customCheckbox}
              onCheck={(
                checkedKeysValue:
                  | {
                      checked: Key[];
                      halfChecked: Key[];
                    }
                  | Key[],
              ) => setFieldValue('leftPermissions', checkedKeysValue)}
              checkedKeys={values.leftPermissions}
            />
            <Tree
              checkable
              defaultExpandAll
              treeData={rightTreeData}
              className={styles.customCheckbox}
              onCheck={(
                checkedKeysValue:
                  | {
                      checked: Key[];
                      halfChecked: Key[];
                    }
                  | Key[],
              ) => setFieldValue('rightPermissions', checkedKeysValue)}
              checkedKeys={values.rightPermissions}
            />
          </Flex>
        </Flex>
      </Flex>
    </ModalDialog>
  );
};

export default ProfileModal;
