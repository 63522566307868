import { FC, PropsWithChildren, useEffect } from 'react';
import { Header, Sidemenu } from '../';
import useHttpService from '../../services/hook';
import { RequestMethods } from '../../services/enum';
import { IRolePermissions } from './type';
import { useAppDispatch, useAppSelector } from '../../store';
import { userActions } from '../../store/reducers/userReducer';
import { IUserInfo } from '../../store/reducers/userReducer/type';

const Layout: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const executeService = useHttpService();
  const { role } = useAppSelector((state) => state.userReducer.userInfo);

  const handleFetchUserInfo = async () => {
    const response = await executeService<IUserInfo>({
      method: RequestMethods.GET,
      url: 'auth/user/me/',
    });
    if (response && 'id' in response) {
      dispatch(userActions.changeUserInfo(response));
    }
  };

  const handleFetchUserPermissions = async () => {
    const response = await executeService<IRolePermissions>({
      method: RequestMethods.GET,
      url: `auth/role/${role.id}/`,
    });
    if (response && 'id' in response) {
      dispatch(userActions.changeUserPermissions(response.permissions));
    }
  };

  useEffect(() => {
    handleFetchUserInfo();
  }, []);

  useEffect(() => {
    role.id && handleFetchUserPermissions();
  }, [role.id]);

  return (
    <div>
      <Header />
      <div style={{ display: 'flex', width: '100%' }}>
        <Sidemenu />
        <div style={{ width: '100%' }}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
