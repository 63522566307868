import { FC, useRef } from 'react';
import {
  Button,
  Checkbox,
  DatePicker,
  Flex,
  Input,
  Select,
  Typography,
  Row,
  Col,
} from 'antd';
import dayjs from 'dayjs';
import { ModalDialog } from '../';
import { IProps } from './type';
import styles from './index.module.scss';
import { PlusOutlined } from '@ant-design/icons';
import { CheckSibaOrganization } from '../../helpers/Organization';
import { useAppSelector } from '../../store';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

const ExpeditionCertificateModal: FC<IProps> = (props) => {
  const { getFieldProps, setFieldValue, values, errors, touched } =
    props.formik;
  const uploadInput = useRef<HTMLInputElement | null>(null);
  const { organization } = useAppSelector(
    (state) => state.userReducer.userInfo,
  );

  return (
    <ModalDialog
      title={props.title}
      subTitle={props.subTitle}
      open={props.open}
      handleOk={props.handleSubmit}
      handleCancel={props.handleCancel}
    >
      <Flex gap="middle" vertical>
        <Flex align="flex-start" justify="space-between">
          <Typography className={styles.labelTitle}>Firma Bilgileri</Typography>
          <Select
            id="organization"
            value={
              props.organizations.find(
                (item) => item.id === values.organization || organization,
              )?.name || undefined
            }
            placeholder="Firma Seçiniz"
            style={{ width: 200 }}
            className={styles.input}
            disabled={!CheckSibaOrganization()}
            options={props.organizations.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            onChange={(value) => setFieldValue('organization', value)}
          />
        </Flex>
        <Flex vertical gap="middle">
          <Typography className={styles.labelTitle}>Emtia Bilgileri</Typography>
          <Row align="middle" justify="space-between" gutter={16}>
            <Col span={8}>
              <Input
                placeholder="Emtia Cinsi"
                {...getFieldProps('goods_type')}
              />
              {touched.goods_type && errors.goods_type && (
                <div className={styles.error}>{errors.goods_type}</div>
              )}
            </Col>
            <Col span={8}>
              <Input
                type="number"
                placeholder="Emtia Miktarı"
                {...getFieldProps('goods_quantity')}
              />
              {touched.goods_quantity && errors.goods_quantity && (
                <div className={styles.error}>{errors.goods_quantity}</div>
              )}
            </Col>
            <Col span={8}>
              <Select
                id="goods_unit"
                value={values.goods_unit || undefined}
                placeholder="Emtia Birimi"
                options={props.unitOfGoods.map((uog) => ({
                  value: uog.key,
                  label: uog.value,
                }))}
                onChange={(value) => setFieldValue('goods_unit', value)}
                style={{
                  width: '100%',
                }}
              />
              {touched.goods_unit && errors.goods_unit && (
                <div className={styles.error}>{errors.goods_unit}</div>
              )}
            </Col>
          </Row>
          <Row align="middle" justify="space-between" gutter={16}>
            <Col span={6}>
              <Input
                type="number"
                placeholder="Emtia Bedeli"
                {...getFieldProps('insurance_value')}
              />
              {touched.insurance_value && errors.insurance_value && (
                <div className={styles.error}>{errors.insurance_value}</div>
              )}
            </Col>
            <Col span={6}>
              <Select
                id="insurance_value_unit"
                value={values.insurance_value_unit || undefined}
                placeholder="Para Birimi"
                style={{ width: 150 }}
                options={props.currencies.map((c) => ({
                  value: c.key,
                  label: c.value,
                }))}
                onChange={(value) =>
                  setFieldValue('insurance_value_unit', value)
                }
              />
              {touched.insurance_value_unit && errors.insurance_value_unit && (
                <div className={styles.error}>
                  {errors.insurance_value_unit}
                </div>
              )}
            </Col>
            <Col span={6}>
              <DatePicker
                id="invoice_date"
                name="invoice_date"
                format="YYYY-MM-DD"
                value={
                  dayjs(values.invoice_date).isValid()
                    ? dayjs(values.invoice_date)
                    : null
                }
                placeholder="Fatura Tarihi"
                onChange={(date) =>
                  setFieldValue(
                    'invoice_date',
                    dayjs(date).format('YYYY-MM-DD'),
                  )
                }
              />
              {touched.invoice_date && errors.invoice_date && (
                <div className={styles.error}>{errors.invoice_date}</div>
              )}
            </Col>
            <Col span={6}>
              <Input
                placeholder="Fatura No"
                {...getFieldProps('invoice_number')}
              />
              {touched.invoice_number && errors.invoice_number && (
                <div className={styles.error}>{errors.invoice_number}</div>
              )}
            </Col>
          </Row>
        </Flex>
        {props.type === 'EDIT' && (
          <Flex vertical gap="middle">
            <Typography className={styles.labelTitle}>
              Poliçe Bilgileri
            </Typography>
            <Flex gap="middle" justify="space-between">
              <Flex vertical style={{ width: '33%' }}>
                <Input
                  type="number"
                  placeholder="Poliçe Değeri"
                  {...getFieldProps('policy_value')}
                />
                {touched.policy_value && errors.policy_value && (
                  <div className={styles.error}>{errors.policy_value}</div>
                )}
              </Flex>
              <Flex vertical style={{ width: '33%' }}>
                <Select
                  id="policy_value_unit"
                  value={values.policy_value_unit || undefined}
                  placeholder="Değer Birimi"
                  options={props.currencies.map((c) => ({
                    value: c.key,
                    label: c.value,
                  }))}
                  onChange={(value) =>
                    setFieldValue('policy_value_unit', value)
                  }
                />
                {touched.policy_value_unit && errors.policy_value_unit && (
                  <div className={styles.error}>{errors.policy_value_unit}</div>
                )}
              </Flex>
              <Flex vertical style={{ width: '33%' }}>
                <Input
                  placeholder="Poliçe No"
                  {...getFieldProps('policy_number')}
                />
                {touched.policy_number && errors.policy_number && (
                  <div className={styles.error}>{errors.policy_number}</div>
                )}
              </Flex>
            </Flex>
          </Flex>
        )}

        <Flex vertical gap="middle">
          <Typography className={styles.labelTitle}>
            Sevkiyat Bilgileri
          </Typography>
          <Row align="middle" justify="space-between" gutter={16}>
            <Col span={12}>
              <Select
                id="transportation_type"
                value={values.transportation_type || undefined}
                placeholder="Sevkiyat Tipi"
                options={props.transportationTypes.map((tt) => ({
                  value: tt.key,
                  label: tt.value,
                }))}
                onChange={(value) =>
                  setFieldValue('transportation_type', value)
                }
                style={{
                  width: '100%',
                }}
              />
              {touched.transportation_type && errors.transportation_type && (
                <div className={styles.error}>{errors.transportation_type}</div>
              )}
            </Col>
            <Col span={12}>
              <DatePicker
                style={{
                  width: '100%',
                }}
                id="expedition_date"
                name="expedition_date"
                format="YYYY-MM-DD"
                value={
                  dayjs(values.expedition_date).isValid()
                    ? dayjs(values.expedition_date)
                    : null
                }
                placeholder="Sevk Tarihi"
                onChange={(date) =>
                  setFieldValue(
                    'expedition_date',
                    dayjs(date).format('YYYY-MM-DD'),
                  )
                }
              />
              {touched.expedition_date && errors.expedition_date && (
                <div className={styles.error}>{errors.expedition_date}</div>
              )}
            </Col>
          </Row>
          <Row align="middle" justify="space-between" gutter={16}>
            <Col span={12}>
              <Input
                placeholder="Çıkış Ülkesi"
                {...getFieldProps('origin_country')}
              />
              {touched.origin_country && errors.origin_country && (
                <div className={styles.error}>{errors.origin_country}</div>
              )}
            </Col>
            <Col span={12}>
              <Input
                placeholder="Varış Ülkesi"
                {...getFieldProps('destination_country')}
              />
              {touched.destination_country && errors.destination_country && (
                <div className={styles.error}>{errors.destination_country}</div>
              )}
            </Col>
          </Row>
        </Flex>
        {props.type === 'EDIT' && (
          <Flex justify="space-between">
            <Typography>
              {values.upload_files?.[0]?.name || 'Ek Dosya'}
            </Typography>
            <Flex vertical align="flex-end">
              <input
                hidden
                ref={uploadInput}
                type="file"
                onChange={props.handleUploadFile}
              />
              <Button
                className={styles.button}
                onClick={() => uploadInput.current?.click()}
              >
                <PlusOutlined className={styles.buttonIcon} />
                <div className={styles.buttonText}>Dosya Ekle</div>
              </Button>
            </Flex>
          </Flex>
        )}
        <Flex vertical gap="middle">
          <Typography className={styles.labelTitle}>Taşıt Bilgileri</Typography>
          <Flex gap="middle" justify="space-between">
            <Flex vertical style={{ width: '50%' }}>
              <Select
                id="vehicle_type"
                value={values.vehicle_type || undefined}
                placeholder="Taşıt Cinsi"
                options={props.vehicleTypes.map((vt) => ({
                  value: vt.key,
                  label: vt.value,
                }))}
                onChange={(value) => setFieldValue('vehicle_type', value)}
              />
              {touched.vehicle_type && errors.vehicle_type && (
                <div className={styles.error}>{errors.vehicle_type}</div>
              )}
            </Flex>
            <Flex vertical style={{ width: '50%' }}>
              <Input
                placeholder="Taşıt Bilgisi"
                {...getFieldProps('vehicle_info')}
              />
              {touched.vehicle_info && errors.vehicle_info && (
                <div className={styles.error}>{errors.vehicle_info}</div>
              )}
            </Flex>
          </Flex>
        </Flex>
        {props.type === 'EDIT' && (
          <Flex justify="space-between">
            <Typography className={styles.labelTitle}>Durum Bilgisi</Typography>
            <Select
              id="recent_status"
              value={values.recent_status}
              style={{ width: 175 }}
              className={styles[`statusDropdown-${values.recent_status}`]}
              options={props.statusChoices.map((sc) => ({
                value: sc.key,
                label: sc.value,
              }))}
              onChange={(value) => setFieldValue('recent_status', value)}
            />
          </Flex>
        )}
        <Flex vertical gap="middle">
          <Typography className={styles.labelTitle}>Açıklama</Typography>
          <Flex vertical>
            <Input.TextArea
              placeholder="Açıklama"
              {...getFieldProps('description')}
            />
            {touched.description && errors.description && (
              <div className={styles.error}>{errors.description}</div>
            )}
          </Flex>
        </Flex>
        {props.type === 'EDIT' && (
          <Flex gap="small" align="flex-start">
            <Checkbox
              id="email_permission"
              name="email_permission"
              checked={values.email_permission}
              onChange={(e: CheckboxChangeEvent) =>
                setFieldValue('email_permission', e.target.checked)
              }
            />
            <div className={styles.mailCheckbox}>
              Durum Bilgisi ‘Onaylandı’ Olarak Güncellendiğinde Nakliyat Sefer
              Bilgilerini Firmanın İlgili Modül Kullanıcılarına Mail Olarak
              İlet.
            </div>
          </Flex>
        )}
      </Flex>
    </ModalDialog>
  );
};

export default ExpeditionCertificateModal;
